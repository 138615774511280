import React from "react"
import { withImageGrid } from "./withImageGrid"
import { HeroCard } from "../Hero/Card/HeroCard"
import { Wrapper, CardWrapper } from "./ImageGridStyles"
import { ImageGridCard } from "./Card/ImageGridCard"
import { TextButtonsGrid } from "../../TextButtonsGrid/TextButtonsGrid"

export const ImageGrid = withImageGrid(
  ({ images, ratios, itemSpacing, handlePromoClick }) => (
    <Wrapper itemSpacing={itemSpacing}>
      {images?.map((image, index) => {
        if (image?._type === "imageCardHero") {
          return (
            <CardWrapper key={image?._key} itemSpacing={itemSpacing} style={{ flex: ratios[index].width }}>
              <HeroCard
                card={image}
                ratio={ratios[index]}
                handlePromoClick={handlePromoClick}
              />
            </CardWrapper>
          )
        }
        if (image?._type === "imageCardNormal") {
          return (
            <CardWrapper key={image?._key} itemSpacing={itemSpacing} style={{ flex: ratios[index].width }}>
              <ImageGridCard
                card={image}
                ratio={ratios[index]}
                handlePromoClick={handlePromoClick}
              />
            </CardWrapper>
          )
        }
        if (image?._type === "pureBackgroundColorCard") {
          return (
            <CardWrapper
              key={image?._key}
              background={image?.backgroundColor?.hex}
              itemSpacing={itemSpacing}
              onClick={handlePromoClick}
              style={{ flex: ratios[index] }}
            >
              <TextButtonsGrid content={image?.content} />
            </CardWrapper>
          )
        }
      })}
    </Wrapper>
  ),
)

export default ImageGrid
